import { Fragment } from 'react'

import { encode } from 'querystring'

import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import { useRouter } from 'next/router'

import HomePage from '@/stories/home'
import usePageLogic from '@helpers/page-logic'
import ROUTES from '@helpers/routes'
import getWordpressData from '@helpers/word-press/get-wordpress'
import HeadTag from 'components/head'

export async function getServerSideProps({ req, query }: GetServerSidePropsContext) {
  const { cookies } = req
  const hasLocationSet = cookies.eaze_coordinates && cookies.eaze_placeId
  // cookies.eaze_loggedin is a boolean in a string ('true' || 'false') so check for existence and parse
  const isLoggedIn = cookies.eaze_loggedin && JSON.parse(cookies.eaze_loggedin)

  if (hasLocationSet || isLoggedIn) {
    const queryParams = new URLSearchParams(encode(query)).toString()
    const destination = queryParams ? `${ROUTES.MENU}?${queryParams}` : ROUTES.MENU
    return {
      redirect: {
        destination,
        permanent: false
      }
    }
  }

  const data = await getWordpressData('landing')

  return {
    props: {
      ...data,
    }
  }
}

function IndexPage(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const router = useRouter()
  // set up polling and other page logic
  usePageLogic()
  return (<Fragment>
    <HeadTag pathname={router.pathname}  path={router.pathname} />
    <HomePage {...props} />
  </Fragment>)
}

export default IndexPage
